export const state = () => ({
  isVisible: false,
  isHelperVisible: false,
  isNavigating: 0
})

export const mutations = {
  toggleIsVisible (state) {
    // console.log('TOGGLE NAV')
    state.isVisible = !state.isVisible
  },
  setIsHelperVisible (state, boolean) {
    state.isHelperVisible = boolean
  },
  setIsNavigating (state) {
    // console.log('STORE NAV')
    state.isNavigating = new Date()
  }
}
