import { render, staticRenderFns } from "./PageError.vue?vue&type=template&id=7cbe708a"
import script from "./PageError.vue?vue&type=script&lang=js"
export * from "./PageError.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModulesIndex: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/modules/ModulesIndex.vue').default,SnippetFooter: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetFooter.vue').default})
