import { theme } from '@/tailwind.config.js'

export default function (to, from, savedPosition) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        x: 0,
        y: 0
      })
      // console.log('SCROLL BEHAVIOR', savedPosition)
    }, parseInt(theme.transitionDuration.page.replace('ms', '')) / 2)
  })
}
