export default ({ app }, inject) => {
  /*
  * https://stackoverflow.com/questions/51296969/vue-js-how-can-nexttick-ensure-that-dom-render-is-completed-after-data-change
  * https://forum.vuejs.org/t/does-nexttick-work-weirdly/42918/7
  */
  inject('sleep', (ms) => {
    return new Promise ((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  })
}
