
import pageProperties from '@/mixins/pageProperties'
export default {
  mixins: [
    pageProperties
  ],

  methods: {
    showNext () {
      if (this.validation[this.current] && this.state !== 'loading') {
        this.$store.commit('form/setAction', 'showNext')
      } else {
        this.$store.commit('notification/setItem', { content: { text: this.$t(`form.notification.step${this.current + 1}`) }})
      }
    },
    goToParent () {
      this.$router.push(this.getParentPagePath())
    },
    goToLastScreen () {
      this.$store.commit('form/setState', 'default')
    }
  },

  computed: {
    isThisVisible () {
      return this.$store.state.layout.isTemplateFormVisible
    },
    current () {
      return this.$store.state.form.current
    },
    length () {
      return this.$store.state.form.length
    },
    validation () {
      return this.$store.state.form.validation
    },
    state () {
      return this.$store.state.form.state
    }
  }
}
