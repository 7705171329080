import { theme } from '@/tailwind.config.js';

export default {
  data() {
    return {
      isClosed: true,
      styleShowmore: {
        maxHeight: 0,
      },
    };
  },

  methods: {
    async toggleShowmore(event, callback = false) {
      if (event) event.preventDefault();

      const el = this.$refs['showmore'];
      if (!el) return
      /*
       * Reset from none to the elements real scrollHeight as max-height
       * None is not animateable
       */
      if (!this.isClosed) {
        this.styleShowmore.maxHeight = `${el.scrollHeight}px`;
        /*
         * If not wait for DOM update the element would directly go from
         * none (open) to 0 (closed)
         * without taking the above maxHeight = scrollHeight into account
         * because it changes faster then the browser renders the style
         */
        await this.$nextTick();
        await this.$nextDOMUpdate();
      }

      /*
       * Switch
       */
      this.isClosed = !this.isClosed;

      /*
       * Animate to
       * Either 0 (closed)
       * Or current (!) scrollHeight (open)
       */
      if (this.isClosed) {
        this.styleShowmore.maxHeight = `0px`;
      } else {
        this.styleShowmore.maxHeight = `${el.scrollHeight}px`;
      }

      /*
       * Execute callback function if any
       */
      if (callback && typeof callback === 'function') {
        callback();
      }

      /*
       * Reset max-height to none
       * Due to the screen is resizeable by the user
       * And therefor scrollHeight + max-height would change
       */
      setTimeout(() => {
        if (!this.isClosed) {
          this.styleShowmore.maxHeight = `none`;
        }
        this.$store.commit('layout/setShouldUpdateFrameScrollHeight')
      }, theme.transitionDuration['showMore'].replace('ms', ''));
    },
  },
};
