
export default {
  props: {
    tooltip: String,
    icon: String,
    schema: String,
    isDisabled: Boolean,
  },

  computed: {
    strokeType () {
      return 'butt'
    }
  },
}
