import { render, staticRenderFns } from "./LayoutControlBack.vue?vue&type=template&id=0db7f8d7&scoped=true"
import script from "./LayoutControlBack.vue?vue&type=script&lang=js"
export * from "./LayoutControlBack.vue?vue&type=script&lang=js"
import style0 from "./LayoutControlBack.vue?vue&type=style&index=0&id=0db7f8d7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db7f8d7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasicIconButton: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/basic/BasicIconButton.vue').default})
