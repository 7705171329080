
export default {
  props: {
    item: Object
  },

  data () {
    return {
      connections: null
    }
  },
};
