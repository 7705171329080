export default ({ app, route }, inject) => {
  /*
  * https://stackoverflow.com/questions/51296969/vue-js-how-can-nexttick-ensure-that-dom-render-is-completed-after-data-change
  * https://forum.vuejs.org/t/does-nexttick-work-weirdly/42918/7
  */
  inject('setWebsiteTitle', (website, page) => {
    const seperator = ' | '
    const title = !['/de/index', '/en/index'].includes(page.locale) ? website.title + seperator + page.title : page.title
    app.store.commit('seo/setTitle', title)
  })
}
