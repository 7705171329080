export const state = () => ({
  isVisible: false,
  isOpen: false,
  closeTracklist: 0,
});

export const mutations = {
  setIsVisible(state, boolean) {
    state.isVisible = boolean
  },
  setIsOpen(state, boolean) {
    state.isOpen = boolean
  },
  changeCloseTracklist (state) {
    state.closeTracklist++
  }
};
