export default {
  data () {
    return {
      continuousSliderMatrix: [],
      continuousInterval: null
    }
  },
  methods: {
    isContinuousIn (index) {
      if (this.continuousSliderMatrix.length === 2) return this.continuousSliderMatrix[index]
      return index === this.continuousSliderMatrix.length - 1 && this.continuousSliderMatrix.indexOf(true) === 0
      ? true
      : index >= this.continuousSliderMatrix.indexOf(true) - 1 && index <= this.continuousSliderMatrix.indexOf(true)
    },
    isContinuousOut (index) {
      if (this.continuousSliderMatrix.length === 2) return this.continuousSliderMatrix[index]
      return index === this.continuousSliderMatrix.length - 1 && this.continuousSliderMatrix.indexOf(true) === 0
      ? false
      : index < this.continuousSliderMatrix.indexOf(true) - 1 || index > this.continuousSliderMatrix.indexOf(true)
    }
  },
  mounted () {
    this.$refs.continuousSlide.forEach((item, index) => {
      this.$set(this.continuousSliderMatrix, index, false)
    })

    this.$set(this.continuousSliderMatrix, 0, true)

    if (this.continuousSliderMatrix.length === 1) return

    this.continuousInterval = setInterval(() => {
      const current = this.continuousSliderMatrix.indexOf(true)
      this.$set(this.continuousSliderMatrix, current, false)

      if (this.continuousSliderMatrix[current + 1] === false) {
        this.$set(this.continuousSliderMatrix, current + 1, true)
      } else {
        this.$set(this.continuousSliderMatrix, 0, true)
      }

    }, this.continuousSliderTimeout)
  },

  beforeDestroy () {
    if (this.continuousInterval) clearInterval(this.continuousInterval)
  }
}
