export const state = () => ({
  item: null,
  closeKey: 0
})

export const mutations = {
  setItem (state, object) {
    state.item = object
  },
  actionClose (state) {
    state.closeKey = new Date()
  }
}
