/*
* Usage
*

:class="{
  'transition duration-1/2': transitionAnimating,
  'opacity-0 transform translate-y-5': transitionHidden,
  'opacity-1 transform translate-y-0': transitionVisible
}"

*/

import { theme } from '@/tailwind.config.js';

export default {
  data () {
    return {
      isEnterDone: false
    }
  },

  watch: {
    isEnterDoneStore (val) {
      this.isEnterDone = true
    }
  },

  computed: {
    isEntering () {
      let _path = this.$route.path
      if (!_path.endsWith('/')) _path = _path + '/'
      // console.log('ENTERING', this.$store.state.pageTransition.isEntering, _path, this.$store.state.pageTransition.isEntering === _path)
      return this.$store.state.pageTransition.isEntering === _path
    },
    isLeaving () {
      let _path = this.$route.path
      if (!_path.endsWith('/')) _path = _path + '/'
      // console.log('LEAVING', this.$store.state.pageTransition.isLeaving, _path, this.$store.state.pageTransition.isLeaving !== _path)
      return this.$store.state.pageTransition.isLeaving !== _path
    },
    isEnterDoneStore () {
      return this.$store.state.pageTransition.isEnterDone
    }
  },
}
