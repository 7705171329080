export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","name":"English","file":"en/index.yaml"},{"code":"de","iso":"de-DE","name":"Deutsch","file":"de/index.yaml"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.jgast.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: true,
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English","file":"en/index.yaml"},{"code":"de","iso":"de-DE","name":"Deutsch","file":"de/index.yaml"}],
  localeCodes: ["en","de"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "/",
  5: "i",
  6: "n",
  7: "d",
  8: "e",
  9: "x",
  10: ".",
  11: "y",
  12: "a",
  13: "m",
  14: "l",
  15: "\"",
  16: ":",
  17: "\"",
  18: ".",
  19: ".",
  20: "/",
  21: "l",
  22: "o",
  23: "c",
  24: "a",
  25: "l",
  26: "e",
  27: "s",
  28: "/",
  29: "e",
  30: "n",
  31: "/",
  32: "i",
  33: "n",
  34: "d",
  35: "e",
  36: "x",
  37: ".",
  38: "y",
  39: "a",
  40: "m",
  41: "l",
  42: "\"",
  43: ",",
  44: "\"",
  45: "d",
  46: "e",
  47: "/",
  48: "i",
  49: "n",
  50: "d",
  51: "e",
  52: "x",
  53: ".",
  54: "y",
  55: "a",
  56: "m",
  57: "l",
  58: "\"",
  59: ":",
  60: "\"",
  61: ".",
  62: ".",
  63: "/",
  64: "l",
  65: "o",
  66: "c",
  67: "a",
  68: "l",
  69: "e",
  70: "s",
  71: "/",
  72: "d",
  73: "e",
  74: "/",
  75: "i",
  76: "n",
  77: "d",
  78: "e",
  79: "x",
  80: ".",
  81: "y",
  82: "a",
  83: "m",
  84: "l",
  85: "\"",
  86: "}",
}

export const localeMessages = {
  'en/index.yaml': () => import('../../locales/en/index.yaml' /* webpackChunkName: "lang-en/index.yaml" */),
  'de/index.yaml': () => import('../../locales/de/index.yaml' /* webpackChunkName: "lang-de/index.yaml" */),
}
