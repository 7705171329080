export const state = () => ({
  frameHeight: 0,
  frameScrollHeight: 0,
  frameWidth: 0,
  isFrameVisible: true,
  isTemplateFormVisible: false,
  isTemplatePageVisible: false,
  breakpoints: {},
  shouldUpdateFrameScrollHeight: 0,
  pageTemplate: '',
  backgroundColor: null,
  isNavigationToggleVisible: true,
  scrollToTopKey: 0,
  isOverlaySeen: true
})

export const mutations = {
  setIsOverlaySeen (state, boolean) {
    state.isOverlaySeen = boolean
  },
  actionScrollToTop (state) {
    state.scrollToTopKey = new Date()
  },
  setIsTemplatePageVisible (state, boolean) {
    state.isTemplatePageVisible = boolean
  },
  setIsTemplateFormVisible (state, boolean) {
    state.isTemplateFormVisible = boolean
  },
  setBackgroundColor (state, stringHex) {
    state.backgroundColor = stringHex
  },
  setFrameHeight (state, number) {
    state.frameHeight = number
  },
  setFrameWidth (state, number) {
    state.frameWidth = number
  },
  setBreakpoint (state, array) {
    state.breakpoints[array[0]] = array[1]
  },
  setFrameScrollHeight (state, number) {
    state.frameScrollHeight = number
  },
  setShouldUpdateFrameScrollHeight (state) {
    state.shouldUpdateFrameScrollHeight = Date.now()
  },
  setPageTemplate (state, string) {
    state.pageTemplate = string
  },
  setIsFrameVisible (state, boolean) {
    state.isFrameVisible = boolean
  },
}
