
import pageProperties from '@/mixins/pageProperties.js'
import pageTransition from '@/mixins/pageTransition.js'
import { theme } from '@/tailwind.config.js'

export default {
  mixins: [pageProperties, pageTransition],

  data() {
    return {
      navigation: null,
      isOpen: false,
      isVisible: false,
      isBlurring: false,
      isNavigating: false,
    }
  },

  watch: {
    async storeIsVisible(val) {
      if (val) {
        this.isOpen = true
        await this.$nextDOMUpdate()
        this.isVisible = true
      } else {
        this.isVisible = false
        setTimeout(() => {
          if (!this.isVisible) this.isOpen = false
          if (!this.isVisible) this.isNavigating = false
        }, theme.transitionDuration['nav'].replace('ms', ''))
      }
    },

    storeIsNavigating() {
      this.isNavigating = true
    },
  },

  computed: {
    storeIsVisible() {
      return this.$store.state.nav.isVisible
    },
    storeIsNavigating() {
      return this.$store.state.nav.isNavigating
    },
  },

  fetchKey: 'LayoutNav',
  async fetch() {
    let res = await this.$content(this.$i18n.locale, { deep: true })
      .where({
        template: 'navigation',
        published: true,
      })
      .only(['content'])
      .fetch()

    this.navigation = res[0]
  },
}
