
import pageProperties from '@/mixins/pageProperties'

export default {
  mixins: [ pageProperties ],

  computed: {
    isThisVisible () {
      return this.$store.state.layout.isTemplatePageVisible
    }
  }
}
