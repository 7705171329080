export const state = () => ({
});

function getPagesWithSpecificContent (contentPropertyArray, app) {
  return new Promise(async (resolve, reject) => {

    const data = await app.$content({ deep: true })
      .where({
        dir: { $ne: '/' },
        published: true
      })
      .sortBy('sorting')
      .only(['id', 'group', 'parent', 'multilingual', 'locale', 'template', 'language']) // w/ content
      .fetch();

    if (data && Array.isArray(data)) {
      data.forEach(item => {
        if (item.content) {
          for (let contentProperty in item.content) {
            if (!contentPropertyArray.includes(contentProperty)) {
              delete item.content[contentProperty]
            }
          }
        }
      })
    }

    /*
    * Custom JGAST
    */
    const footer = await app.$content({ deep: true})
        .where({ template: 'footer', published: true })
        .only(['id', 'locale', 'group', 'language', 'template', 'content'])
        .fetch()

    const merge = data.map(i => {
      const _footer = footer.find(j => j.id === i.id)
      if (_footer) {
        return _footer
      }
      else {
        return i
      }
    })
    resolve(merge)
  })
}

export const actions = {
  async nuxtServerInit ({ dispatch, commit }, { app }) {
    const all = await getPagesWithSpecificContent(null, app) // ['types']
    commit('database/setReferences', all)
  },
}

export const mutations = {
};
