export const state = () => ({
  submitImages: 0,
  current: null,
  length: null,
  action: '',
  validation: {},
  state: ''
})

export const mutations = {
  setCurrent (state, number) {
    state.current = number
  },
  setLength (state, number) {
    state.length = number
  },
  setAction (state, string){
    state.action = string
  },
  submitImages (state) {
    state.submitImages = new Date()
  },
  setValidation (state, tupel) {
    this._vm.$set(state.validation, tupel[0], tupel[1])
  },
  setState (state, string) {
    state.state = string
  }
}
