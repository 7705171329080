export const state = () => ({
  /*
  * A list of pages without content
  */
  references: []
})

export const mutations = {
  setReferences (state, array) {
    state.references = array
  }
}

export const getters = {
  /*
  * By Id
  */
  getReferenceById: (state) => (id) => {
    return state.references.find(i => i.id === id)
  },

  /*
  * By Group
  */
  getReferencesByGroup: (state) => (group) => {
    return state.references.filter(i => i.group === group)
  },
  getReferenceByGroupAndLanguage: (state) => (group, language) => {
    return state.references.filter(i => i.group === group).find(i => i.language === language)
  },

  /*
  * By Template
  */
  getReferencesByTemplate: (state) => (template) => {
    return state.references.filter(i => i.template === template)
  },
  getReferencesByTemplateAndLanguage: (state) => (template, language) => {
    return state.references.filter(i => i.template === template).filter(i => i.language === language)
  },

  /*
  * By Template
  */
  getReferenceByLocale: (state) => (locale) => {
    return state.references.find(i => i.locale === locale)
  }
}
