
export default {
  props: {
    type: String,
    href: String,
    schema: String,
    size: String
  },

  computed: {
    componentProp () {
      return this.type === "link"
      ? {
        is: 'n-link',
        to: `${this.href}${this.$route.query.blur === null? '?blur' : ''}`
      } : {
        is: 'button',
      }
    }
  }
};
