
import pageProperties from '@/mixins/pageProperties'

export default {
  props: {
    item: Object
  },

  mixins: [pageProperties]
};
