
import Config from '@/config/images.yaml'

export default {
  name: 'BasicImage',

  props: {
    filename: String,
    config: String,
    caption: String,
    isCover: Boolean,
    notSetCarouselItem: Boolean,
    notUpdateFrameScrollHeight: Boolean,
    onlyBlur: Boolean,
    widthAndHeight: Array
  },

  data() {
    return {
      preload: false,
      isLoaded: false,
    };
  },

  computed: {
    localSrc() {
      return `/uploads/${this.filename}`;
    },
    localSrcSet() {
      if (!Config[this.config]) return ''

      let srcset = '';
      const extension = this.localSrc.split('.').pop();
      const filePath = this.localSrc.replace(`.${extension}`, '');

      if (extension === 'svg') return ''

      const sorting = Object.keys(Config[this.config]).sort((a, b) => b - a)
      sorting.forEach(s => {
        let value = Config[this.config][s];
        srcset += filePath + '---' + value + '.' + extension + ' ' + s + 'w, ';
      })

      srcset = srcset.slice(0, -2);
      return srcset;
    },
    localSrcBlur () {
      const extension = this.localSrc.split('.').pop();
      const filePath = this.localSrc.replace(`.${extension}`, '');
      return filePath + '---' + 'blur' + '.' + 'jpg'
    }
  },

  methods: {
    async onImageLoaded () {
      await this.$nextTick()
      await this.$nextDOMUpdate()
      this.isLoaded = true
      this.$emit('loaded')
      if (!this.notUpdateFrameScrollHeight) this.$store.commit('layout/setShouldUpdateFrameScrollHeight')
    }
  },
  mounted () {
    // await this.$nextTick()
    // await this.$nextDOMUpdate()
    //
    // console.log(this.$refs)
    // if (this.$refs.image) {
    //   if (this.$refs.image.complete) {
    //     this.imageIsLoaded()
    //   } else {
    //     this.$refs.image.addEventListener('load', this.imageIsLoaded)
    //   }
    // }


    if (!this.notSetCarouselItem) {
      this.$store.commit('carousel/setItems', {
        route: this.$route.path,
        content: {
          image: [{
            filename: this.filename
          }],
        }
      })
    }
  },

  beforeDestroy() {
    this.$refs.image.removeEventListener('load', this.imageIsLoaded)
  },
};
