export default {
  methods: {
    getSlug (page) {
      if (!page || !page.multilingual) return ''
      return page.multilingual[this.$i18n.locale].slug
    },

    getTitle (page) {
      if (!page || !page.multilingual) return ''
      return page.multilingual[this.$i18n.locale].title
    },

    getPath (page) {
      if (!page) return '/'
      const _page = this.$store.getters['database/getReferenceByGroupAndLanguage'](page.group, this.$i18n.locale)
      let path = _page.locale.replace('/index', '/')
      if (path.startsWith(`/${this.$i18n.defaultLocale}`)) path = path.replace(`/${this.$i18n.defaultLocale}`, '')
      return path
    },

    getPathByGroup (group) {
      const pages = this.$store.getters['database/getReferencesByGroup'](group)
      const page = pages.find(p => p.language === this.$i18n.locale)
      return this.getPath(page)
    },

    getTitleByGroup (group) {
      const pages = this.$store.getters['database/getReferencesByGroup'](group)
      const page = pages.find(p => p.language === this.$i18n.locale)
      return this.getTitle(page)
    },

    getParentPagePath () {
      let path = this.$route.path
      if (!path.endsWith('/')) path = `${path}/`
      let parts = path.split('/')
      parts.splice(-2, 1)
      return parts.join('/')
    },

    navigateToParent () {
      this.$router.push(this.getParentPagePath())
    }
  }
}
