export const BasicButton = () => import('../../components/basic/BasicButton.vue' /* webpackChunkName: "components/basic-button" */).then(c => wrapFunctional(c.default || c))
export const BasicIconButton = () => import('../../components/basic/BasicIconButton.vue' /* webpackChunkName: "components/basic-icon-button" */).then(c => wrapFunctional(c.default || c))
export const BasicImage = () => import('../../components/basic/BasicImage.vue' /* webpackChunkName: "components/basic-image" */).then(c => wrapFunctional(c.default || c))
export const BasicLink = () => import('../../components/basic/BasicLink.vue' /* webpackChunkName: "components/basic-link" */).then(c => wrapFunctional(c.default || c))
export const BasicLoading = () => import('../../components/basic/BasicLoading.vue' /* webpackChunkName: "components/basic-loading" */).then(c => wrapFunctional(c.default || c))
export const BasicLogo = () => import('../../components/basic/BasicLogo.vue' /* webpackChunkName: "components/basic-logo" */).then(c => wrapFunctional(c.default || c))
export const BasicText = () => import('../../components/basic/BasicText.vue' /* webpackChunkName: "components/basic-text" */).then(c => wrapFunctional(c.default || c))
export const BasicVideo = () => import('../../components/basic/BasicVideo.vue' /* webpackChunkName: "components/basic-video" */).then(c => wrapFunctional(c.default || c))
export const LayoutBackgroundCarousel = () => import('../../components/layout/LayoutBackgroundCarousel.vue' /* webpackChunkName: "components/layout-background-carousel" */).then(c => wrapFunctional(c.default || c))
export const LayoutControlBack = () => import('../../components/layout/LayoutControlBack.vue' /* webpackChunkName: "components/layout-control-back" */).then(c => wrapFunctional(c.default || c))
export const LayoutControlForm = () => import('../../components/layout/LayoutControlForm.vue' /* webpackChunkName: "components/layout-control-form" */).then(c => wrapFunctional(c.default || c))
export const LayoutControlFrame = () => import('../../components/layout/LayoutControlFrame.vue' /* webpackChunkName: "components/layout-control-frame" */).then(c => wrapFunctional(c.default || c))
export const LayoutControlNav = () => import('../../components/layout/LayoutControlNav.vue' /* webpackChunkName: "components/layout-control-nav" */).then(c => wrapFunctional(c.default || c))
export const LayoutControlNavHelper = () => import('../../components/layout/LayoutControlNavHelper.vue' /* webpackChunkName: "components/layout-control-nav-helper" */).then(c => wrapFunctional(c.default || c))
export const LayoutCookieBanner = () => import('../../components/layout/LayoutCookieBanner.vue' /* webpackChunkName: "components/layout-cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/LayoutHeader.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutLanguageSwitch = () => import('../../components/layout/LayoutLanguageSwitch.vue' /* webpackChunkName: "components/layout-language-switch" */).then(c => wrapFunctional(c.default || c))
export const LayoutLogo = () => import('../../components/layout/LayoutLogo.vue' /* webpackChunkName: "components/layout-logo" */).then(c => wrapFunctional(c.default || c))
export const LayoutMainScrollHelper = () => import('../../components/layout/LayoutMainScrollHelper.vue' /* webpackChunkName: "components/layout-main-scroll-helper" */).then(c => wrapFunctional(c.default || c))
export const LayoutNav = () => import('../../components/layout/LayoutNav.vue' /* webpackChunkName: "components/layout-nav" */).then(c => wrapFunctional(c.default || c))
export const LayoutNotification = () => import('../../components/layout/LayoutNotification.vue' /* webpackChunkName: "components/layout-notification" */).then(c => wrapFunctional(c.default || c))
export const LayoutOverlay = () => import('../../components/layout/LayoutOverlay.vue' /* webpackChunkName: "components/layout-overlay" */).then(c => wrapFunctional(c.default || c))
export const LayoutRoutesGenerateHelper = () => import('../../components/layout/LayoutRoutesGenerateHelper.vue' /* webpackChunkName: "components/layout-routes-generate-helper" */).then(c => wrapFunctional(c.default || c))
export const ModulesAccordion = () => import('../../components/modules/ModulesAccordion.vue' /* webpackChunkName: "components/modules-accordion" */).then(c => wrapFunctional(c.default || c))
export const ModulesCTA = () => import('../../components/modules/ModulesCTA.vue' /* webpackChunkName: "components/modules-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const ModulesCarousel = () => import('../../components/modules/ModulesCarousel.vue' /* webpackChunkName: "components/modules-carousel" */).then(c => wrapFunctional(c.default || c))
export const ModulesDivider = () => import('../../components/modules/ModulesDivider.vue' /* webpackChunkName: "components/modules-divider" */).then(c => wrapFunctional(c.default || c))
export const ModulesEmbed = () => import('../../components/modules/ModulesEmbed.vue' /* webpackChunkName: "components/modules-embed" */).then(c => wrapFunctional(c.default || c))
export const ModulesGallery = () => import('../../components/modules/ModulesGallery.vue' /* webpackChunkName: "components/modules-gallery" */).then(c => wrapFunctional(c.default || c))
export const ModulesHeadline = () => import('../../components/modules/ModulesHeadline.vue' /* webpackChunkName: "components/modules-headline" */).then(c => wrapFunctional(c.default || c))
export const ModulesHero = () => import('../../components/modules/ModulesHero.vue' /* webpackChunkName: "components/modules-hero" */).then(c => wrapFunctional(c.default || c))
export const ModulesImage = () => import('../../components/modules/ModulesImage.vue' /* webpackChunkName: "components/modules-image" */).then(c => wrapFunctional(c.default || c))
export const ModulesIndex = () => import('../../components/modules/ModulesIndex.vue' /* webpackChunkName: "components/modules-index" */).then(c => wrapFunctional(c.default || c))
export const ModulesLinks = () => import('../../components/modules/ModulesLinks.vue' /* webpackChunkName: "components/modules-links" */).then(c => wrapFunctional(c.default || c))
export const ModulesMarquee = () => import('../../components/modules/ModulesMarquee.vue' /* webpackChunkName: "components/modules-marquee" */).then(c => wrapFunctional(c.default || c))
export const ModulesNotification = () => import('../../components/modules/ModulesNotification.vue' /* webpackChunkName: "components/modules-notification" */).then(c => wrapFunctional(c.default || c))
export const ModulesSlider = () => import('../../components/modules/ModulesSlider.vue' /* webpackChunkName: "components/modules-slider" */).then(c => wrapFunctional(c.default || c))
export const ModulesText = () => import('../../components/modules/ModulesText.vue' /* webpackChunkName: "components/modules-text" */).then(c => wrapFunctional(c.default || c))
export const ModulesTwoColumns = () => import('../../components/modules/ModulesTwoColumns.vue' /* webpackChunkName: "components/modules-two-columns" */).then(c => wrapFunctional(c.default || c))
export const ModulesVideo = () => import('../../components/modules/ModulesVideo.vue' /* webpackChunkName: "components/modules-video" */).then(c => wrapFunctional(c.default || c))
export const PageArticle = () => import('../../components/page/PageArticle.vue' /* webpackChunkName: "components/page-article" */).then(c => wrapFunctional(c.default || c))
export const PageBlog = () => import('../../components/page/PageBlog.vue' /* webpackChunkName: "components/page-blog" */).then(c => wrapFunctional(c.default || c))
export const PageCategory = () => import('../../components/page/PageCategory.vue' /* webpackChunkName: "components/page-category" */).then(c => wrapFunctional(c.default || c))
export const PageError = () => import('../../components/page/PageError.vue' /* webpackChunkName: "components/page-error" */).then(c => wrapFunctional(c.default || c))
export const PageForm = () => import('../../components/page/PageForm.vue' /* webpackChunkName: "components/page-form" */).then(c => wrapFunctional(c.default || c))
export const PageHome = () => import('../../components/page/PageHome.vue' /* webpackChunkName: "components/page-home" */).then(c => wrapFunctional(c.default || c))
export const PagePage = () => import('../../components/page/PagePage.vue' /* webpackChunkName: "components/page-page" */).then(c => wrapFunctional(c.default || c))
export const SnippetAccordion = () => import('../../components/snippets/SnippetAccordion.vue' /* webpackChunkName: "components/snippet-accordion" */).then(c => wrapFunctional(c.default || c))
export const SnippetAccordionItem = () => import('../../components/snippets/SnippetAccordionItem.vue' /* webpackChunkName: "components/snippet-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const SnippetCarousel = () => import('../../components/snippets/SnippetCarousel.vue' /* webpackChunkName: "components/snippet-carousel" */).then(c => wrapFunctional(c.default || c))
export const SnippetCarouselPagination = () => import('../../components/snippets/SnippetCarouselPagination.vue' /* webpackChunkName: "components/snippet-carousel-pagination" */).then(c => wrapFunctional(c.default || c))
export const SnippetFooter = () => import('../../components/snippets/SnippetFooter.vue' /* webpackChunkName: "components/snippet-footer" */).then(c => wrapFunctional(c.default || c))
export const SnippetFrame = () => import('../../components/snippets/SnippetFrame.vue' /* webpackChunkName: "components/snippet-frame" */).then(c => wrapFunctional(c.default || c))
export const SnippetHero = () => import('../../components/snippets/SnippetHero.vue' /* webpackChunkName: "components/snippet-hero" */).then(c => wrapFunctional(c.default || c))
export const SnippetHeroHeadlines = () => import('../../components/snippets/SnippetHeroHeadlines.vue' /* webpackChunkName: "components/snippet-hero-headlines" */).then(c => wrapFunctional(c.default || c))
export const SnippetHeroImages = () => import('../../components/snippets/SnippetHeroImages.vue' /* webpackChunkName: "components/snippet-hero-images" */).then(c => wrapFunctional(c.default || c))
export const SnippetMenu = () => import('../../components/snippets/SnippetMenu.vue' /* webpackChunkName: "components/snippet-menu" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCalendar = () => import('../../components/form/FormFieldCalendar.vue' /* webpackChunkName: "components/form-field-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCheckbox = () => import('../../components/form/FormFieldCheckbox.vue' /* webpackChunkName: "components/form-field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormFieldInput = () => import('../../components/form/FormFieldInput.vue' /* webpackChunkName: "components/form-field-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelectButton = () => import('../../components/form/FormFieldSelectButton.vue' /* webpackChunkName: "components/form-field-select-button" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelectImage = () => import('../../components/form/FormFieldSelectImage.vue' /* webpackChunkName: "components/form-field-select-image" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSlider = () => import('../../components/form/FormFieldSlider.vue' /* webpackChunkName: "components/form-field-slider" */).then(c => wrapFunctional(c.default || c))
export const FormFieldTextArea = () => import('../../components/form/FormFieldTextArea.vue' /* webpackChunkName: "components/form-field-text-area" */).then(c => wrapFunctional(c.default || c))
export const FormFieldUpload = () => import('../../components/form/FormFieldUpload.vue' /* webpackChunkName: "components/form-field-upload" */).then(c => wrapFunctional(c.default || c))
export const FormGroupBudget = () => import('../../components/form/FormGroupBudget.vue' /* webpackChunkName: "components/form-group-budget" */).then(c => wrapFunctional(c.default || c))
export const FormGroupConversation = () => import('../../components/form/FormGroupConversation.vue' /* webpackChunkName: "components/form-group-conversation" */).then(c => wrapFunctional(c.default || c))
export const FormGroupDetails = () => import('../../components/form/FormGroupDetails.vue' /* webpackChunkName: "components/form-group-details" */).then(c => wrapFunctional(c.default || c))
export const FormGroupEnvironment = () => import('../../components/form/FormGroupEnvironment.vue' /* webpackChunkName: "components/form-group-environment" */).then(c => wrapFunctional(c.default || c))
export const FormGroupForm = () => import('../../components/form/FormGroupForm.vue' /* webpackChunkName: "components/form-group-form" */).then(c => wrapFunctional(c.default || c))
export const FormGroupNotes = () => import('../../components/form/FormGroupNotes.vue' /* webpackChunkName: "components/form-group-notes" */).then(c => wrapFunctional(c.default || c))
export const FormGroupSelectImages = () => import('../../components/form/FormGroupSelectImages.vue' /* webpackChunkName: "components/form-group-select-images" */).then(c => wrapFunctional(c.default || c))
export const FormGroupUploadImages = () => import('../../components/form/FormGroupUploadImages.vue' /* webpackChunkName: "components/form-group-upload-images" */).then(c => wrapFunctional(c.default || c))
export const FormInputs = () => import('../../components/form/FormInputs.vue' /* webpackChunkName: "components/form-inputs" */).then(c => wrapFunctional(c.default || c))
export const FormProgress = () => import('../../components/form/FormProgress.vue' /* webpackChunkName: "components/form-progress" */).then(c => wrapFunctional(c.default || c))
export const FormStep = () => import('../../components/form/FormStep.vue' /* webpackChunkName: "components/form-step" */).then(c => wrapFunctional(c.default || c))
export const UtilityFooter = () => import('../../components/utility/UtilityFooter.vue' /* webpackChunkName: "components/utility-footer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
