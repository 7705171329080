import Vue from 'vue'

import { Input, Slider, Progress, Upload, Checkbox, Loading } from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

export default () => {
  Vue.use(Input, { locale })
  Vue.use(Slider, { locale })
  Vue.use(Progress, { locale })
  Vue.use(Upload, { locale })
  Vue.use(Checkbox, { locale })
  Vue.use(Loading.directive, { locale })
}
