export default {
  data () {
    return {
      ISobserver: null,
      isInView: false
    }
  },
  mounted () {
    this.ISobserver = new IntersectionObserver((entries) => {
      const ratio = entries[0].intersectionRatio
      if (ratio < 0.65) this.isInView = false
      if (ratio >= 0.65) this.isInView = true
    }, {
      root: document.querySelector('#frame'),
      threshold: [0.65, 1]
    });
    this.ISobserver.observe(this.$refs.isInView)
  },
  beforeDestroy () {
    try {
      if (this.ISobserver) this.ISobserver.unobserve(this.$refs.isInView)
    } catch (e) {
      // console.log('OBSERVER FAILS SILENT')
    }

  }
}
