
import continuousSlider from '@/mixins/continuousSlider'

export default {
  props: {
    isFullScreen: Boolean,
    images: Array
  },

  data () {
    return {
      continuousSliderTimeout: 2500
    }
  },

  mixins: [continuousSlider]
}
