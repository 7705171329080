
import pageProperties from '@/mixins/pageProperties'

export default {
  props: {
    item: Object,
    isNested: Boolean
  },

  mixins: [ pageProperties ],

  data () {
    return {
      isFullyVisible: true,
      frameEl: null,
      percentScrolled: 0
    }
  },

  methods: {
    scrollHorizontal (e) {
      if (this.isFullyVisible) {
        const box = this.$refs.slider.getBoundingClientRect()
        let percent = Math.round(((-1 * box.top) / (box.height - this.frameHeight)) * 10000) / 100
        if (percent > 100) percent = 100
        if (percent < 0) percent = 0
        this.percentScrolled = percent
      }
    },
    /*
    * DEPRECATED
    */
    observeElement () {
      const observer = new IntersectionObserver((entries) => {
        this.isFullyVisible = entries[0].isIntersecting
      }, {
        threshold: 1
      });
      observer.observe(this.$refs.sticky)
    }
  },

  computed: {
    frameHeight () {
      return this.$store.state.layout.frameHeight
    },
    styleObject () {
      return {
        transform: `translate3d(-${this.percentScrolled * (this.item.content.images.length - 1)}%, 0, 0)`
      }
    }
  },

  mounted () {
    // this.observeElement()
    if (!this.item.content.isVertical) {
      const frames = document.querySelectorAll('[frame-wrapper]')
      if (frames.length === 2) this.frameEl = frames[1]
      if (frames.length === 1) this.frameEl = frames[0]
      if (this.frameEl) {
        if (this.frameEl.dataset.type === 'no-space') {
          window.addEventListener('scroll', this.scrollHorizontal)
        } else {
          this.frameEl.addEventListener('scroll', this.scrollHorizontal)
        }
      }
    }
  },

  beforeDestroy () {
    if (this.frameEl && !this.item.content.isVertical) {
      if (this.frameEl.dataset.type === 'no-space') {
        window.removeEventListener('scroll', this.scrollHorizontal)
      } else {
        this.frameEl.removeEventListener('scroll', this.scrollHorizontal)
      }
    }
  }
};
