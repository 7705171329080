
import continuousSlider from '@/mixins/continuousSlider'
import marked from '@/mixins/marked'

export default {
  props: {
    headlines: Array,
    hasCTA: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      continuousSliderTimeout: 4000
    }
  },

  mixins: [marked, continuousSlider]
}
