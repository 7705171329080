
import isInView from '@/mixins/isInView'
export default {
  props: {
    item: Object,
    isNested: Boolean
  },

  mixins: [ isInView ],

  data () {
    return {
      action: ''
    }
  },

  watch: {
    isInView (val) {
      if (val) this.action = 'play'
      if (!val) this.action = 'pause'
    }
  }
}
