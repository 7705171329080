export const state = () => ({
  isEntering: '',
  isLeaving: '',
  isEnterDone: 0,
});

export const mutations = {
  setEnter (state, string) {
    state.isEntering = string
  },
  setLeave (state, string) {
    state.isLeaving = string
  },
  setEnterDone (state) {
    state.isEnterDone = new Date()
  }
};
