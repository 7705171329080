
import pageProperties from '@/mixins/pageProperties'
export default {
  props: {
    item: Object
  },

  mixins: [pageProperties],

  computed: {
    pages () {
      return this.$store.getters['database/getReferencesByTemplateAndLanguage']('legal', this.$i18n.locale)
    },
    footer () {
      return this.$store.getters['database/getReferenceByGroupAndLanguage'](this.item.content.footer, this.$i18n.locale)
    },
    bgColor () {
      return this.footer && this.footer.content && this.footer.content.colorSchema
      ? this.footer.content.colorSchema
      : 'white'
    },
    txtColor () {
      return this.footer && this.footer.content && this.footer.content.colorSchema && this.footer.content.colorSchema === 'black'
      ? 'white'
      : 'black'
    }
  },

  fetchKey: 'SnippetFooter',
  async fetch() {
    // if (this.item.content && this.item.content.footer) {
    //   let res = await this.$content(this.$i18n.locale, { deep: true})
    //     .where({ 'group': this.item.content.footer })
    //     .only('content')
    //     .fetch()
    //   this.footer = res[0]
    // }

    // this.pages = await this.$content(this.$i18n.locale, { deep: true })
    //   .where({
    //     $and: [
    //       { 'parent.template': { $eq: 'more' } },
    //       { template: { $eq: 'legal' } },
    //     ],
    //   })
    //   .sortBy('sorting')
    //   .only(['title', 'slug', 'id', 'locale', 'multilingual'])
    //   .fetch();
    // await this.$nextDOMUpdate()
    // this.$store.commit('layout/setShouldUpdateFrameScrollHeight')
  },
}
