
import { theme } from '@/tailwind.config.js'

export default {
  name: 'BasicVideo',

  props: {
    format: String,
    action: String,
    sources: Array,
    isPortrait: Boolean,
    isCover: Boolean,
    isAutoplay: Boolean,
    isFullScreen: Boolean,
    isThumbnail: Boolean,
    hasPadding: Boolean,
    hasAudio: Boolean,
    notSetCarouselItem: Boolean,
    notHasMouseEvents: Boolean,
    isNested: Boolean
  },

  data() {
    return {
      videoSrc: '',
      videoType: '',
      isPaused: true,
      isMuted: true,
      isUserAction: false,
      isLoaded: false,
      timer: null,
      options: {
        muted: true,
        autoplay: false,
        controls: false,
        loop: true,
        sources: null, // this.sources
        playsinline: true,
        loadingSpinner: false
      }
    }
  },

  watch: {
    action (val) {
      if (val === 'play') this.play()
      if (val === 'pause') this.pause()
      if (val === 'reset') this.reset()
    }
  },

  methods: {
    toggleMute(e) {
      e.stopPropagation()

      if (this.$refs.video.muted === true) {
        this.$refs.video.muted = false
      } else {
        this.$refs.video.muted = true
      }

      this.isMuted = this.$refs.video.muted
    },
    togglePlayPause() {
      if (this.$refs.video.paused) {
        this.play()
      } else {
        this.pause()
      }

      clearTimeout(this.timer)
      this.isUserAction = true
      this.timer = setTimeout(() => {
        if (this.isUserAction) this.isUserAction = false
      }, theme.transitionDuration.fadeInOut.replace('ms', ''))
    },
    play () {
      if (this.isPaused) {
        this.isPaused = false
        this.$refs.video.play().then(() => {
        }).catch((e) => {
          // SILENCE console.log('IS ERROR', e)
        })
      }

    },
    pause () {
      if (!this.isPaused) {
        this.isPaused = true
        this.$refs.video.pause()
      }
    },
    reset () {
      this.isPaused = true
      this.$refs.video.pause()
      this.$refs.video.currentTime = 0
    }
  },

  mounted () {
    if (this.isAutoplay) this.options.autoplay = true
    if (this.isFullScreen) {
      if (window.innerWidth < 1920) {
        this.options.sources = this.sources.map(s => {
          return {
            ...s,
            src: s.src.replace('id=175', 'id=174')
          }
        })
      } else {
        this.options.sources = this.sources
      }
    } else {
      this.options.sources = this.sources.map(s => {
        return {
          ...s,
          src: s.src.replace('id=175', 'id=174')
        }
      })
    }

    this.$refs.video.addEventListener('canplay', (e) => {
      this.$emit('duration', this.$refs.video.duration)
      this.$emit('loaded')
      this.$store.commit('layout/setShouldUpdateFrameScrollHeight')
      this.isLoaded = true
    })

    this.videoSrc = this.options.sources[0].src
    this.videoType = this.options.sources[0].type

    if (!this.notSetCarouselItem) {
      this.$store.commit('carousel/setItems', {
        route: this.$route.path,
        content: {
          vimeo: this.sources
        }
      })
    }
  },
}
