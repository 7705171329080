
export default {
  data () {
    return {
      legal: null
    }
  },

  fetchKey: 'LayoutError',
  async fetch () {
    const res = await this.$content(this.$i18n.locale, { deep: true })
      .where({
        $and: [
          { template: { $eq: 'error' } },
        ],
      })
      .sortBy('sorting')
      .only(['title', 'slug', 'id', 'locale', 'multilingual', 'content'])
      .fetch();

    this.legal = res[0]
    this.$store.commit('layout/setBackgroundColor', this.legal.content.backgroundColor)
  },
}
