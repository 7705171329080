
import isInView from '@/mixins/isInView'
export default {
  data () {
    return {
      isAlreadyPopped: false
    }
  },

  props: {
    item: Object
  },

  mixins: [ isInView ],

  // computed: {
  //   item () {
  //     return this.$store.state.notification.item
  //   },
  // },

  watch: {
    isInView (val) {
      if (val) {
        if (!this.isAlreadyPopped) {
          this.$store.commit('notification/setItem', this.item)
          this.isAlreadyPopped = true
        }
      }
    }
  },
};
