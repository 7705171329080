
import pageProperties from '@/mixins/pageProperties'

export default {
  props: {
    current: Number,
    progress: Number,
  },

  mixins: [pageProperties],

  methods: {
    showPrev () {
      this.$store.commit('form/setAction', 'showPrev')
    }
  },

  computed: {
    state () {
      return this.$store.state.form.state
    }
  }
}
