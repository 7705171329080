import { render, staticRenderFns } from "./ModulesTwoColumns.vue?vue&type=template&id=f7b5b14e&scoped=true"
import script from "./ModulesTwoColumns.vue?vue&type=script&lang=js"
export * from "./ModulesTwoColumns.vue?vue&type=script&lang=js"
import style0 from "./ModulesTwoColumns.vue?vue&type=style&index=0&id=f7b5b14e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7b5b14e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModulesIndex: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/modules/ModulesIndex.vue').default})
