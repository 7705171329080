import { marked } from 'marked';

marked.setOptions({
  breaks: true
});

export default {
  methods: {
    marked(string) {
      if (string === null || string === undefined || typeof string !== 'string')
        return;
      return this.checkForAnchorLink(
        this.checkForInternalLink(
        this.addBlankToLink(
        this.checkForRomanLists(
        this.replaceShy(
            marked(
              this.checkForEmptyLineSubstitute(
              this.escapeAsteriks(
              this.escapeLists(string)
              )
              )
            )
        )
        )
        )
        )
      );
    },
    escapeLists (string) {
      return string
        .replace(/([0-9]+)\./g, '$1\\.')
        .replace(/- ([0-9]+)\\\./g, '$1.')
    },
    escapeAsteriks (string) {
      return string.replace(/\*/g, '\\*')
    },
    removeP (string) {
      if (string === null || string === undefined) return;
      return string.replace(/<p>/g, '').replace(/<\/p>/g, '');
    },
    addBlankToLink (string) {
      return string.replace(/<a /g, '<a target="_blank" ');
    },
    checkForInternalLink (string) {
      return string
        .replace(/target="_blank" href="\//g, 'ref="internal-link" href="/')
        .replace(/href="\//g, 'ref="internal-link" href="/');
    },
    checkForFileLink (string) {
      return string.replace(/href="file:/g, 'target="_blank" href="');
    },
    checkForAnchorLink (string) {
      return string.replace(
        /target="_blank" href="#/g,
        'ref="anchor-link" href="#'
      );
    },
    checkForRomanLists (string) {
      return string.replace(/<ul>\s<li>I. /g, '<ol class="roman"><li>')
    },
    checkForEmptyLineSubstitute (string) {
      return string.replace(/\\ /g, '&nbsp;')
    },
    clickInternalLink(e) {
      e.preventDefault();
      this.$router.push(e.target.getAttribute('href'));
    },
    replaceShy(string) {
      return string.replace(/\|/g, '&shy;');
    },
    exists(entity) {
      if (entity && typeof entity === 'string' && entity.length > 0)
        return true;
      return false;
    },
    makeHeadline(string) {
      return this.removeP(this.replaceShy(this.marked(string)));
    },
  },
  mounted() {
    if (this.$el && this.$el.querySelectorAll) this.$el.querySelectorAll('[ref="internal-link"]').forEach((a) => {
      if (a.dataset.internal_link === undefined)
        a.addEventListener('click', this.clickInternalLink);
      a.dataset.internal_link = 1;
    });
  },
  beforeDestroy() {
    if (this.$el && this.$el.querySelectorAll) this.$el.querySelectorAll('[ref="internal-link"]').forEach((a) => {
      a.removeEventListener('click', this.clickInternalLink);
    });
  },
};
