export const state = () => ({
  items: [],
  inViewFilename: '', // either img or vimeo url
  isCarouselPaused: false,
})

export const mutations = {
  setInViewFilename (state, string) {
    state.inViewFilename = string
  },
  setItems (state, object) {
    let isAvailable = false
    if (object.content.image) isAvailable = state.items.find(i => i.content.image && i.content.image[0].filename === object.content.image[0].filename)
    if (object.content.vimeo) isAvailable = state.items.find(i => i.content.vimeo && i.content.vimeo[0].src === object.content.vimeo[0].src)
    if (!isAvailable) state.items.push(object)
  },
  resetCarousel (state) {
    // console.log('RESET CAROUSEL')
    state.items = []
    state.inViewFilename = ''
  },
  setIsCarouselPaused (state, boolean) {
    state.isCarouselPaused = boolean
  }
}
