
export default {
  computed: {
    isFormVisible() {
      return (
        !this.$store.state.layout.isTemplateFormVisible &&
        !this.$store.state.layout.isTemplatePageVisible
      )
    },
  },
}
